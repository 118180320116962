import React from "react"
import { Box, PseudoBox, Link, Flex, List, ListItem, Image } from "@chakra-ui/core"
// import ResponsiveEmbed from "react-responsive-embed"
import {
  SectionHeading,
  SubHeading,
  Bold,
  Paragraph,
  Button,
} from "../ui-elements"
// Icons
import recordIcon from "../../images/VS_Icons_color-03.png"
import registerIcon from "../../images/VS_Icons_color-04.png"
import protectIcon from "../../images/VS_Icons_color-05.png"

const HowDoesItWork = () => {
  return (
    <Box py="16" color="theme-dark">
      <Box
        px="4"
        w="100%"
        maxW="containers.xl"
        mx="auto"
        id="how-does-verifismart-work-record"
      >
        <SectionHeading>Why protect your work?</SectionHeading>
        {/* <Box my="16" maxW="containers.md" w="100%" mx="auto">
          <ResponsiveEmbed
            ratio="16:9"
            src="https://www.youtube.com/embed/9xwazD5SyVg"
          />
        </Box> */}

        <SubHeading>
          The protection of your work with VERIFiSMART is as simple as 1-2-3:
        </SubHeading>
        <List ml="4" listStyleType="none" listStylePosition="outside">
          <ListItem my="8">
            <Flex alignItems="center" flexDirection="column">
              <SubHeading color="theme-green" marginTop="0">
                1. RECORD
              </SubHeading>  
              <Image height="180px" src={recordIcon} />
            </Flex>            
            <Paragraph
              as="span"
              fontWeight="200"
              id="how-does-verifismart-work-register"
            >
              To substantiate your rights, you need to keep sufficient records
              that provide evidence of the{" "}
              <Bold color="theme-purple">
                process of independent creation of your work
              </Bold>
              . Simply create your account and start recording the development
              of your work and projects from beginning to end. Our platform
              offers encrypted storage so that no one (not even us) can access
              your work until you are ready to release it to the public.
            </Paragraph>
            <Paragraph mt="3" fontWeight="200">
              Good record keeping is vital to defend and protect your work!
            </Paragraph>
          </ListItem>
          <ListItem my="8">
            <Flex alignItems="center" flexDirection="column">
              <SubHeading marginTop="0">
                2. REGISTER
              </SubHeading>
              <Image height="180px" src={registerIcon} />
            </Flex>
            <Paragraph as="span" fontWeight="200">
              Intellectual property protection operates on a "first come, first
              served" basis. Copyrights and design rights grant{" "}
              <Bold color="theme-purple">
                protection from the moment of creation
              </Bold>{" "}
              therefore, proving when you created your work is a crucial step
              towards protection and enforcement of your rights.
            </Paragraph>
            <Paragraph
              mt="3"
              mb="2"
              fontWeight="200"
              id="how-does-verifismart-work-protect"
            >
              With VERIFiSMART you can register all completed versions of your
              work and projects, including updates and improvements. We will
              register your work in the blockchain, create a unique
              cryptographic fingerprint of your work, and issue a digital
              certificate with a unique hash and an{" "}
              <Bold color="theme-purple">unchallengeable timestamp</Bold> – this
              timestamp will be the indisputable proof of the date and time of
              creation of your work.
            </Paragraph>
            <Paragraph mt="3" fontWeight="200">
              Keep your certificate safe, it is your proof of ownership!
            </Paragraph>
          </ListItem>
          <ListItem my="8">
            <Flex alignItems="center" flexDirection="column">            
              <SubHeading color="theme-purple" marginTop="0">
                3. PROTECT
              </SubHeading>
              <Image height="180px" src={protectIcon} />            
            </Flex>
            <Paragraph as="span" fontWeight="200">
              It is essential for the protection of your rights to take active
              steps to{" "}
              <Bold color="theme-purple">
                watch for unauthorized third-party uses and distributions of
                your work
              </Bold>
              . You can use our{" "}
              <Bold color="theme-purple">Smart Track&trade;</Bold> service for
              this! Keep track of any potential infringements and claims in your
              dashboard for better monitoring and control.
            </Paragraph>
            <Paragraph mt="3" fontWeight="200">
              When publishing or posting your work online, remember to use our
              registration stamp as notice of protection and deterrer for
              infringers – you can easily download it from your dashboard.
            </Paragraph>
          </ListItem>
        </List>
        <Paragraph>
          <Bold color="theme-purple">
            AFTER FOLLOWING THESE SIMPLE STEPS YOU CAN THEN BE READY TO PROMOTE,
            EXPLOIT AND/OR COMMERCIALIZE YOUR WORK WITH PEACE OF MIND!
          </Bold>
        </Paragraph>
        <SubHeading>
          VERIFiSMART offers the same benefits of a notarised certification, in
          a much easier, secured, transparent and cost-effective way!
        </SubHeading>
        <Flex mt="16" justify="center">
          <PseudoBox
            as={Link}
            _hover={{ textDecoration: "none" }}
            href={process.env.GATSBY_APP_URL}
            target="_blank"
          >
            <Button>Protect Your Work Now</Button>
          </PseudoBox>
        </Flex>
      </Box>
    </Box>
  )
}

export default HowDoesItWork
