import React, { useEffect, useState } from "react"
import Layout from '../components/layout';
import SEO from '../components/seo';
import Top from '../components/sections/Top';
import SubtitleVideo from '../components/sections/SubtitleVideo';
import WhatCanYouProtect from '../components/sections/WhatCanYouProtect';
import HowDoesItWork from '../components/sections/HowDoesItWork';
import Faqs from '../components/sections/Faqs';
import VirtualAssistant from "./virtual-assistant"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="VERIFiSMART" description="..." />
      <Top />
      <SubtitleVideo />
      <WhatCanYouProtect />
      <HowDoesItWork />
      <Faqs />
      <VirtualAssistant />
    </Layout>
  );
};

export default IndexPage;
