import React from "react"
import { Box, SimpleGrid, Heading, Text, Flex, Image } from "@chakra-ui/core"
import { SectionHeading } from "../ui-elements"

import artworkIcon from "../../images/VS_icon01-01.png"
import fashionIcon from "../../images/VS_icon02-01.png"
import industrialIcon from "../../images/VS_icon03-01.png"
import musicIcon from "../../images/VS_icon04-01.png"
import literaryIcon from "../../images/VS_icon05-01.png"
import tradeSecretsIcon from "../../images/VS_icon06-01.png"
import softwareIcon from "../../images/VS_icon07-01.png"
import filmsIcon from "../../images/VS_icon09-01.png"

const Type = ({ title, icon = artworkIcon, details }) => (
  <Flex minH="200px" flexDir="column" align="center">
    <Heading size="sm">{title}</Heading>

    <Image height="180px" src={icon} />
    <Text textAlign="center" fontSize="sm">
      {details}
    </Text>
  </Flex>
)

const types = [
  {
    title: "Artwork",
    icon: artworkIcon,
    details:
      "Paintings, drawings, photographs, illustrations, graphic designs, architectural designs, etc.",
  },
  {
    title: "Fashion Designs",
    icon: fashionIcon,
    details: "Clothes, handbags, jewellery, etc.",
  },
  {
    title: "Industrial Designs",
    icon: industrialIcon,
    details: "Shapes and configuration of products.",
  },
  {
    title: "Music and sound recordings",
    icon: musicIcon,
    details:
      "Songs (notes and composition), lyrics and any recordings of music or other sounds.",
  },
  {
    title: "Literary work",
    icon: literaryIcon,
    details:
      "Books, articles, poems, screenplays, scripts, guides, manuals, marketing plans, etc.",
  },
  {
    title: "Trade secrets",
    icon: tradeSecretsIcon,
    details:
      "Any valuable information about your business’ operations, products or procedures not readily available to the public.",
  },
  {
    title: "Software and Database",
    icon: softwareIcon,
    details:
      "Website content and layout; apps and computer program descriptions, functional and technical specifications, source codes and object codes; proprietary databases; etc.",
  },
  {
    title: "Films and videos",
    icon: filmsIcon,
    details:
      "Short films or any video recordings from which a moving image may be produced.",
  },
]

const WhatCanYouProtect = () => {
  return (
    <Box id="what-can-you-protect" py="16" color="theme-dark">
      <Box px="4" w="100%" maxW="containers.xl" mx="auto">
        <SectionHeading>What can you protect?</SectionHeading>
        <SimpleGrid p="16" columns={[1, 1, 2, 4]} spacing={16}>
          {types.map(type => (
            <Type key={type.title} {...type} />
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  )
}

export default WhatCanYouProtect
