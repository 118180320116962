import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/core"

import { SectionHeading, Paragraph, Bold } from "../ui-elements"

const Faqs = () => {
  return (
    <Box py="16" color="theme-dark">
      <Box px="4" w="100%" maxW="containers.xl" mx="auto">
        <SectionHeading>FAQs</SectionHeading>
        <Accordion my="8" allowMultiple>
          <AccordionItem>
            <AccordionHeader>
              <Box py="4" fontSize="xl" flex="1" textAlign="left">
                Why is VERIFiSMART’ s registration certificate useful?
              </Box>
              <AccordionIcon />
            </AccordionHeader>
            <AccordionPanel pb={4}>
              <Paragraph fontWeight="200" fontSize="md" my="4">
                In any legal dispute about infringement of unregistrable
                intellectual property rights the first defence raised from the
                alleged infringer will be for the claimant to prove ownership of
                the infringed right on which it claims ownership. The dispute
                therefore cannot progress to assess whether infringement has
                actually occurred or not, let alone a determination on the
                damages caused by such infringement, without first proving that
                you own such rights in the respective work.
              </Paragraph>
              <Paragraph fontWeight="200" fontSize="md" my="4">
                VERIFiSMART’ s certificate provided an unchallengeable proof of
                the moment on which you created your work by creating a digital
                timestamp that is impossible to be altered or falsified, due to
                its embedment on blockchain technology.
              </Paragraph>
              <Paragraph fontWeight="200" fontSize="md" my="4">
                Our certificate would therefore allow you to submit any claim
                letters to the other party with proof of ownership attached,
                which would then remove the burden of having to prove ownership
                through internal files and records that can be then challenged
                by the other party making that a lengthy and potentially costly
                process.
              </Paragraph>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionHeader>
              <Box py="4" fontSize="xl" flex="1" textAlign="left">
                What information is included in the registration certificate?
              </Box>
              <AccordionIcon />
            </AccordionHeader>
            <AccordionPanel pb={4}>
              <Paragraph fontWeight="200" fontSize="md" my="4">
                The name of the owner of the work (whether it is the original
                author or the company that commissioned its creation) appears at
                the top, together with a registration number and a visible
                digital timestamp.
              </Paragraph>
              <Paragraph fontWeight="200" fontSize="md" my="4">
                The hash code of the work appears then, this is the unique code
                with which your code has been registered on the blockchain and
                which can be used to search for it anytime you need to prove
                that the certificate relates to the work in dispute.
              </Paragraph>
              <Paragraph fontWeight="200" fontSize="md" my="4">
                You don’t need our help to prove ownership. You or any
                third-party can use our{" "}
                <Bold color="theme-purple">Smart Validator&trade;</Bold> Service
                at any time, to find the work that has been registered by using
                the information available on your certificate.
              </Paragraph>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionHeader>
              <Box py="4" fontSize="xl" flex="1" textAlign="left">
                Which files should I just record, and which ones should I
                register?
              </Box>
              <AccordionIcon />
            </AccordionHeader>
            <AccordionPanel pb={4}>
              <Paragraph fontWeight="200" fontSize="md" my="4">
                For any project in which a particular work is slowly developed
                or constantly updated, it is important to keep a secure and
                accurate record of its progress both for personal/commercial
                purposes as well as for proof of ownership.
              </Paragraph>
              <Paragraph fontWeight="200" fontSize="md" my="4">
                If any information or stage of development gets leaked or
                accessed by the wrong person, it could put your final work at
                risk of being copied or used before you are ready to use it
                yourself or release it to the public.
              </Paragraph>
              <Paragraph fontWeight="200" fontSize="md" my="4">
                The advantages offered by VERIFiSMART are that you can encrypt
                and record every stage of development of a work and register the
                final version which will be made available to the public and,
                therefore, on which you would want to secure and enforce
                exclusive intellectual property rights.
              </Paragraph>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionHeader>
              <Box py="4" fontSize="xl" flex="1" textAlign="left">
                How do I find out if someone is using my work without my
                permission?
              </Box>
              <AccordionIcon />
            </AccordionHeader>
            <AccordionPanel pb={4}>
              <Paragraph fontWeight="200" fontSize="md" my="4">
                It is important to actively monitor any unauthorised used of
                your work by running searches online as frequently as possible.
                For certain creative works, such as photographs, images or songs
                you can use our{" "}
                <Bold color="theme-purple">Smart Track&trade;</Bold> Service to
                automatically scan the web and find any product or service that
                may be using the same or similar work as yours in a way that may
                be infringing your rights.
              </Paragraph>
              <Paragraph fontWeight="200" fontSize="md" my="4">
                VERIFiSMART offers a section where you can keep records of any
                potential infringing uses of a particular work that you have
                registered, including information such as the date on which you
                find it, links to the sites on which the infringing goods or
                services are being offered and tracker of any claims submitted.
              </Paragraph>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Box>
  )
}

export default Faqs
