import React from "react"
import {Box} from "@chakra-ui/core"
import video from "../../videos/landing_subtitle.mp4"
// import poster from "../../images/poster_landing_subtitle.jpg"
import {SectionHeading} from "../ui-elements";

const SubtitleVideo = () => (
    <Box py="16" color="theme-dark">
        <Box
            px="4"
            w="100%"
            maxW="containers.xl"
            mx="auto"
            id="how-does-verifismart-work-record-video"
        >
            <SectionHeading>How does VERIFiSMART work?</SectionHeading>
            <Box
                as='video'
                controls
                src={video}
                // poster={poster}
                alt='VerifiSmart landing video'
                objectFit='contain'
                sx={{
                    aspectRatio: '16/9',
                }}
                mt="12"
                mx="auto"
            >
            </Box>
        </Box>
    </Box>
);

export default SubtitleVideo
