import React from "react"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import { Flex, Box, Heading, Text, Icon, Link } from "@chakra-ui/core"
import scrollTo from "gatsby-plugin-smoothscroll"

const Top = () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "top-bg.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      dt: file(relativePath: { eq: "top-background.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      } 
    }
  `)

  const imageData = data?.desktop?.childImageSharp?.fluid

  return (
    <Box id="top-section">
      <BackgroundImage
        style={{
          minHeight: "calc(100vh - 100px)",
          height: "auto",
          backgroundPosition: "right",
        }}
        fluid={imageData}
      >
        <Flex
          p="16"
          justify="center"
          maxW="containers.xl"
          w="100%"
          mx="auto"
          minH="calc(100vh - 100px)"
          color="white"
          px="4"
          flexDir="column"
        >
          <Flex mt="16" flexDir="column" justify="center">
            <Box>
              <Heading as="h1" size="2xl" w={["100%", "100%", "60%"]} fontWeight="200">
                The
                <Box
                  as="span"
                  fontWeight="400"
                  color="theme-purple"
                  style={{ color: "#9A258F" }}
                >
                  {" "}
                  smartest{" "}
                </Box>
                way to protect your intellectual{" "}
                <Text as="span" fontWeight="400" color="theme-green">
                  property
                </Text>
              </Heading>
            </Box>
            <Heading size="lg" fontWeight="400" my="16">
              <Text
                as="span"
                style={{ cursor: "pointer" }}
                onClick={() => scrollTo("#how-does-verifismart-work-record")}
              >
                Record
              </Text>
              <Icon name="chevron-right" />
              <Text
                as="span"
                style={{ cursor: "pointer" }}
                onClick={() => scrollTo("#how-does-verifismart-work-register")}
              >
                Register
              </Text>
              <Icon name="chevron-right" />
              <Text
                as="span"
                style={{ cursor: "pointer" }}
                onClick={() => scrollTo("#how-does-verifismart-work-protect")}
              >
                Protect
              </Text>
            </Heading>
            <Text w={["100%", "100%", "60%"]} fontSize="xl">
              Register your creations and innovations and prevent third-parties
              from unfairly profiting from your work.
            </Text>
          </Flex>
          <Flex
            mt="16"
            color="white"
            height="100px"
            justify="center"
            align="center"
          >
            <Link onClick={() => scrollTo("#what-can-you-protect")}>
              <Icon size="100px" name="chevron-down" />
            </Link>
          </Flex>
        </Flex>
      </BackgroundImage>
    </Box>
  )
}

export default Top
